import React from 'react'
import { Link } from 'gatsby';


const LinkTo = ({linkTo="/", children, className}) => {
    const externalLink = linkTo.includes("http") || linkTo.includes("www")
    const mailTo = linkTo.includes("mailto") 
    if (externalLink) return ( <a  className={className} href={linkTo} target="_blank" rel="noopener noreferrer">{children} </a>)

    if (mailTo) return <a className={className} href={linkTo}>{children} </a>
    return (
        <Link className={className} to={linkTo} >{children}</Link>
    )
}

export default LinkTo