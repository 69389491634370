export const navigation_links = {
    left: [
        {
            page: "Developer",
            linkTo: "/developer",
        },
        {
            page: "Designer",
            linkTo: "/designer",
        },
        {
            page: "Photography",
            linkTo: "https://photos.samloyd.io",
        },
    ],
    right: [
        {
            page: "About",
            linkTo: "/about",
        },
        {
            page: "Blog",
            linkTo: "https://blog.samloyd.io",
        },
        {
            page: "Contact Me",
            linkTo: "/contact",
        },
    ]
}

export const social_links = [
    {
        icon: "fa fa-envelope",
        linkTo: "mailto:sam@samloyd.io",
        text: "sam@samloyd.io"
    },
    {
        icon: "fab fa-twitter",
        linkTo: "https://twitter.com/@MrSamLoyd",
        text: "@MrSamLoyd"
    },
    {
        icon: "fab fa-medium-m",
        linkTo: "https://medium.com/@samloyd",
    },
    {
        icon: "fab fa-instagram",
        linkTo: "https://www.instagram.com/mr_samloyd",
        text: "@mr_samloyd"
    },
    {
        icon: "fab fa-linkedin-in",
        linkTo: "https://www.linkedin.com/in/samloyd",
        text: "Sam Loyd | Helping Companies Ship Products"
    },

   
    
]